
@font-face {
    font-family:"PPCirka";
    src:url("./font/PPCirka-Medium.woff") format("woff"),
        url("./font/PPCirka-Medium.woff2") format("woff"),
        url("./font/PPCirka-Medium.otf") format("opentype");
    font-display:auto;
    font-style:normal;
    font-weight: medium;
    font-stretch:normal;
}

@font-face {
    font-family:"PPNeueMontreal";
    src:url("./font/PPNeueMontreal-Medium.woff") format("woff"),
        url("./font/PPNeueMontreal-Medium.woff2") format("woff2"),        
        url("./font/PPNeueMontreal-Medium.otf") format("opentype");
    font-display:auto;
    font-style: normal;
    font-weight: medium;
    font-stretch:normal;
}
