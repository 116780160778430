@charset "utf-8";

@import url("./fonts.css");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');


/* 초기화 */
html { font-size: 62.5%;  scroll-behavior: smooth; }
body {margin:0;padding:0; font-family: 'PPNeueMontreal', 'Noto Sans KR', sans-serif; color: #000; background:#FFF; }
html, h1, h2, h3, h4, h5, h6, form, fieldset, img {margin:0;padding:0;border:0}
h1, h2, h3, h4, h5, h6 {font-size:1em; font-family: 'PPNeueMontreal', 'Noto Sans KR', sans-serif; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}

ul, dl,dt,dd {margin:0;padding:0;list-style:none}
legend {position:absolute;margin:0;padding:0;font-size:0;line-height:0;text-indent:-9999em;overflow:hidden}
label, input, button, select, img {vertical-align:middle;font-size:1em}
input, button {margin:0;padding:0; font-family: 'PPNeueMontreal','Noto Sans KR', sans-serif; font-size:1em}
input[type="submit"] {cursor:pointer}
button {cursor:pointer}

textarea, select { font-family: 'PPNeueMontreal','Noto Sans KR', sans-serif; font-size:1em}
select {margin:0}
p {margin:0;padding:0;word-break:break-all}
hr {display:none}
pre {overflow-x:scroll;font-size:1.1em}
a {color:#000;text-decoration:none}
button { background: none; border: none;}
a, button { transition: 0.5s;}

*, :after, :before {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

img { max-width: 100%; }

#wrap { display: flex; flex-direction: column; min-height: 100vh; }

.header .hd { display: flex; justify-content:space-between; }
.header .hd .logo { position: fixed; top: 2.91666666667vw; left: 7.08333333333vw;  width: 3.88888888889vw; padding: 0.416666666665vw; z-index: 1000; }
.header .hd  .nav button { position: fixed; z-index: 1003; top: 2.91666666667vw; right: 7.08333333333vw; width: 3.88888888889vw; height: 3.88888888889vw; text-indent: -9999px; text-align: left; font-size: 1.38888888889vw; filter: drop-shadow(1px 2px 11px rgba(0, 0, 0, 0.25)); background: #fff; border-radius: 50%; }
.header .hd  .nav button > span { display: inline-block; width: 8.61111111111vw; text-align: center; }
.header .hd  .nav button::after { content:''; position: absolute; top: 50%; right: 50%; transform: translate(50%,-50%); width: 3.05555555556vw; height: 3.05555555556vw; background: #000 url('images/ico/ico-nav.png') center center no-repeat; background-size: 80%; border-radius: 50%; transition: 0.3s; }
.header .hd  .nav button:hover::after, .header .hd  .nav button.active::after { background: #1323E8 url('images/ico/ico-nav.png') center center no-repeat; background-size: 80%; }
.header .hd  .nav > ul { margin-left: -1.38888888889vw; padding: 0px 1.38888888889vw; display: none; position: absolute; }
.header .hd  .nav > ul > li { position: relative; margin-top: 0.55555555556vw;}
.header .hd  .nav > ul > li > a { position: relative; display: block; width: 8.61111111111vw; height: 3.05555555556vw; line-height: 3.05555555556vw; text-align: center; font-size: 1.38888888889vw; background: #fff; border-radius: 0.34722222222vw;}
.header .hd  .nav > ul > li::after { content: ''; position: absolute; z-index: -1; top: 0px; left: 0px; width: 100%; height: 100%; filter: drop-shadow(1px 2px 11px rgba(0, 0, 0, 0.25)); border-radius: 0.34722222222vw; background: #fff; }

main { position: relative; padding-top: 11vw; flex: 1; display: flex;  flex-direction: column; justify-content: center;  }
.mainBox { position: relative; display: flex; flex-direction: column; height: 100%; padding-bottom: 6.94444444444vw;  }
.futureraText { position: absolute; z-index: 9; width: 100%; left: 0px; bottom: 0px; line-height: 10.29166666667vw; text-align: center; font-size: 14.23611111111vw; }
.futureraText span { font-family:"PPCirka", sans-serif; font-size: 15.19444444444vw; color: #1323E8; }

.mainItem { position: relative; padding-top: 3.47222222222vw; width: 100%; display: flex; justify-content: center; }
.mainItem .item01 { position: absolute; z-index: 4; left: 50%; top: -2.77777777778vw; margin-left: -40.97222222222vw; width: 30.69444444444vw; }
.mainItem .item02 { position: relative; z-index: 3; width: 33.88888888889vw; }
.mainItem .item03 { position: absolute; z-index: 2; left: 50%; top: -7.91666666667vw; width: 29.44444444444vw; margin-left: 4.02777777778vw; }
.mainItem .num { position: absolute; font-size: 0.97222222222vw; }
.mainItem .link { position: absolute; z-index: 10;   }
.mainItem .link button { display: inline-block; line-height: 1.875vw; padding: 0.69444444444vw 1.25vw; font-size: 2.22222222222vw; color: #000; background: #fff; border-radius: 1.38888888889vw; filter: drop-shadow(1px 2px 11px rgba(0, 0, 0, 0.25)); text-transform: uppercase; }
.mainItem .link button:hover { filter: drop-shadow( 1px 2px 11px #0029FF ) }

.mainItem .item01 .num { top: 2.91666666667vw; left: 6.25vw; }
.mainItem .item01 .unionText { position: absolute; top: -0.3vw; right: 0.1vw; transform: rotate(5deg);  font-size: 0.97222222222vw; }
.mainItem .item02 .num { left: 12.08333333333vw; top: 2.43055555556vw; }
.mainItem .item03 .num { top: 3.26388888889vw; left: 18.33333333333vw; }
.mainItem > div.item01 .link { top: 9.23611111111vw; left: 3.95833333333vw; }
.mainItem > div.item02 .link { top: 18.81944444444vw; right: -3.26388888889vw; }
.mainItem > div.item03 .link { top: 7.29166666667vw; left: 18.40277777778vw; }

.footer { margin-top: auto; background: #DBDBDB; border-radius: 1.38888888889vw 1.38888888889vw 0px 0px; }
.footer .ft { display: flex; width: 100%; justify-content: space-between; padding: 1.18055555556vw 1.94444444444vw; font-size: 0.97222222222vw; color: #1323E8; }
.footer .ft address { font-style: normal; }

.method { padding: 0px 6.04166666667vw; }
.method .methodText { font-size: 7.43055555556vw; text-transform: uppercase; }
.method .methodText span { font-family:"PPCirka", sans-serif; font-size: 8.19444444444vw; color: #1323E8; }

.popup { position: fixed; z-index: 1001; top: 0px; left: 0px; width: 100%; height: 100vh; }
.popup::before { content: ''; position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; background: #000; opacity: 0.6; }
.popup::after { content:''; position: fixed; width: 100%; height: 100%; top: 0; left: 0; background: url(images/bg/bg-contact.png) no-repeat center center;  }
.popup .popupContent { position: absolute; height: 100%; width: 100%; z-index: 1001; display: flex; align-items: center; justify-content: center; transform: translateX(-50%); left: 50%; }
.popup.method .popupContent { padding: 50px 0px; align-items: baseline; overflow-y: scroll;  }
.popup .btnClose { position: fixed; z-index: 1002; top: 3.61111111111vw; left: 7.08333333333vw; padding: 0.69444444444vw; color: #fff; font-size: 0.97222222222vw; background: #000; border-radius: 2.36111111111vw; }
.popup .btnClose:hover { background: #1323E8; }
.popup .close { text-align: center; }
.popup .close button { padding: 0.69444444444vw; color: #fff; font-size: 0.97222222222vw; background: #000; border-radius: 2.36111111111vw; }
.popup .close button:hover { background: #1323E8; }

.popup .contact { position: relative; text-align: center; width: 100%;  }
.popup .contact .img { width: 33.61111111111vw; margin: 0 auto; }
.popup .contact .img { max-height: 90vh; }
.popup .contact .link { position: absolute; bottom: 0px; width: 100%; left: 0px; text-align: center; }
.popup .contact .link a { position: relative;  font-size: 9.02777777778vw; color: #fff; }
.popup .contact .link a span { font-family:"PPCirka", sans-serif; font-size: 10.02777777778vw; color: #1323E8; }
.popup .contact .link a::before { content: ''; position: absolute; top: 50%; transform: translateY(-50%); right: -8.05555555556vw; width: 5.97222222222vw; height: 5.97222222222vw; background: #000 url(images/ico/ico-arr.png) no-repeat center center; background-size: 50%; border-radius: 50%; }

.methodContent .visualPopup { position: relative; width: 68.75vw; height: 29.86111111111vw; margin: 0 auto; padding: 1.18055555556vw 7.91666666667vw 0 7.91666666667vw; text-align: center; background: url(images/bg/bg-method.png) no-repeat center top; background-size: 100%;  }
.methodContent .visualPopup::before { content: ''; position: absolute; left: 3.81944444444vw; bottom: 4.02777777778vw; width: 3.40277777778vw; height: 3.40277777778vw; background: url(images/ico/ico-method.png) no-repeat; background-size: 100%;  }
.methodContent .visualPopup::after { content: ''; position: absolute; right: 3.81944444444vw; bottom: 4.02777777778vw; width: 3.40277777778vw; height: 3.40277777778vw; background: url(images/ico/ico-method.png) no-repeat; background-size: 100%; }
.methodContent .visualPopup strong { display: inline-block; padding: 0.69444444444vw; font-size: 1.38888888889vw; background: #fff; border-radius: 0.69444444444vw; }
.methodContent .visualPopup .methodCt { margin-top: 4.86111111111vw; padding-left: 2.29166666666vw; line-height: 1.94444444444vw; font-size: 1.66666666667vw; color: #fff;  }
.methodContent .visualPopup .methodCt > p { text-align: justify; word-break: keep-all; }
.methodContent .visualPopup .methodCt .ko { margin-top: 0.69444444444vw; }

.methodContent .methodCont { transform: translateY(-3.26388888889vw);}
.methodContent .methodCont > div { position: relative; width: 30.69444444444vw; padding: 5.55555555556vw 1.38888888889vw 3.47222222222vw; margin: 0 auto; line-height: 1.45833333333vw; font-size: 1.25vw; color: #fff;  background: rgba(19, 35, 232, 0.2); backdrop-filter: blur(20px); border-radius: 24px; }
.methodContent .methodCont > div::before { content: ''; position: absolute; width: calc(100% - 2.77777777778vw ); top: 2.36111111111vw; left: 1.38888888889vw; height: 1px; background: #fff;  }
.methodContent .methodCont > div::after { content: ''; position: absolute; top: 1.875vw; left: 50%; transform: translateX(-50%); width: 0.80277777778vw; height: 2.68819444444vw; background: url(images/ico/ico-line.png) no-repeat; background-size: 100%; }
.methodContent .methodCont > div > p { text-align: justify; word-break: keep-all; }
.methodContent .methodCont > div .ko { margin-top: 2.36111111111vw; }
.methodContent .methodCont > div:first-child { margin-bottom: 1.59722222222vw; }

.textType01 { text-align: center; font-size: 4.16666666667vw; color: #fff; text-transform: uppercase; }
.textType01 span { font-family:"PPCirka", sans-serif; font-size: 4.88888888889vw; color: #0029FF; }
.popup.method .textType01 { transform: translateY(-3.26388888889vw); margin: 7.29166666667vw 0 5.76388888889vw; }

.mobift { display: none; }
.mobiView { display: none; }

.unionContent { width: 76.45833333333vw; height: 35.27777777778vw; padding-top: 1.38888888889vw; text-align: center; background: url(images/bg/bg-union.png) no-repeat center center; background-size: 100%; }
.unionContent strong { display: inline-block; padding: 0.69444444444vw; font-size: 1.38888888889vw; background: #fff; border-radius: 0.69444444444vw; }

.unionContent .partners { display: flex; justify-content: space-between; align-items: center; padding: 0px 10.88888888889vw; }
.unionContent .partners:nth-child(2) { margin-top: 6.75vw; padding: 0px 11.25vw; }
.unionContent .partners:nth-child(3) { margin-top: 2.43055555556vw; padding: 0 9.56944444444vw; }
.unionContent .partners:nth-child(4) { margin-top: 2.91666666667vw; }
.unionContent .partners .fp { width: 5.0125vw; }
.unionContent .partners .jr { width: 12.54375vw; }
.unionContent .partners .of { width: 11.67847222222vw; }
.unionContent .partners .lsg { width: 10.95902777778vw; }
.unionContent .partners .av { width: 9.92777777778vw; }
.unionContent .partners .mj { width: 7.35625vw; }
.unionContent .partners .bb { width: 7.49722222222vw; }
.unionContent .partners .kratio { width: 8.0375vw; }
.unionContent .partners .tgp { width: 20.67152777778vw; }
.unionContent .partners .seg { width: 9.20347222222vw; }

.archivesContent { display: flex; justify-content: space-between; padding: 0 8.47222222222vw; }
.archivesContent .archives_left { width: 28.125vw; background: url(images/bg/bg-archives.png) no-repeat; background-size: contain;  }
.archivesContent .archives_left strong { margin: 0.83333333333vw 0 0 3.75vw; display: inline-block; padding: 0.69444444444vw; font-size: 1.38888888889vw; background: #fff; border-radius: 0.69444444444vw; }

.archivesContent .archives_right { position: relative;  margin-left: 3.75vw; width: 53.05555555556vw;  padding: 0 1.875vw; }
.archivesContent .archives_right::before { content: ''; position: absolute; z-index: 10; left: 0px; top: 0px; width: 100%; height: 0.69444444444vw; background: rgba(217, 217, 217, 0.2); backdrop-filter: blur(20px); border-radius: 1.59722222222vw; }
.archivesContent .archives_right::after { content: ''; position: absolute; z-index: 10; bottom: 0px; left: 0px; width: 100%; height: 0.69444444444vw; background: rgba(217, 217, 217, 0.2); backdrop-filter: blur(20px); border-radius: 1.59722222222vw; }
.archivesContent .archives_right > div { height: 39.58333333333vw; padding: 1.38888888889vw 0 3.47222222222vw; overflow-y: scroll; }
.archivesContent .archives_right strong { display: inline-block; padding: 0.69444444444vw; font-size: 0.97222222222vw; color: #fff; background: #000; border-radius: 2.08333333333vw; }

.archivesContent .archives_right .futreaNews.unionNews { margin-top: 6.94444444444vw; }
.archivesContent .archives_right .futreaNews > ul > li { margin-top: 1.66666666667vw; }
.archivesContent .archives_right .futreaNews > ul > li:first-child { margin-top: 1.38888888889vw; }
.archivesContent .archives_right .newsBox { position: relative;  padding: 1.38888888889vw 2.43055555556vw; background: #D9D9D9; border-radius: 2.91666666667vw; }
.archivesContent .archives_right .newsBox .tags span { display: inline-block;  margin-left: 4PX; padding: 0.27777777778vw 0.69444444444vw; font-size: 0.83333333333vw; border: 1px solid #000; border-radius: 1.04166666667vw; }
.archivesContent .archives_right .newsBox .tags span:first-child { margin-left: 0px;}
.archivesContent .archives_right .newsBox .newsContent { margin-top: 0.83333333333vw; font-size: 18px; font-family: 'Noto Sans KR', sans-serif; }
.archivesContent .archives_right .newsBox .newsTitle { font-size: 2.08333333333vw; text-transform: uppercase; }
.archivesContent .archives_right .newsBox a { position: absolute; display: inline-block; bottom: 1.38888888889vw; right: 1.38888888889vw; width: 2.43055555556vw; height: 2.43055555556vw; text-indent: -9999px; background: #000 url(images/ico/ico-arr.png) no-repeat center center; background-size: 50%; border-radius: 50%; }

.mainItem .item01 img { animation: 5s moving infinite; }
.mainItem .item02 img { animation: 5s moving infinite 1s; }
.mainItem .item03 img { animation: 5s moving infinite 3s; }


@keyframes moving {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1vw);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and ( max-width: 782px ) {


  @keyframes moving {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .pcView { display: none; }
  .mobiView { display: block; }

  .header .hd .logo { top: 5.12820512821vw; left: 5.12820512821vw; width: 11.28205128205vw; padding: 1.153846153845vw; }
  .header .hd .nav button { top: 5.12820512821vw; right: 5.12820512821vw; width: 11.28205128205vw; height: 11.28205128205vw; background: #fff; border-radius: 50%; }
  .header .hd .nav button::before { content: 'menu'; position: absolute; bottom: -5.12820512821vw; width: 100%; font-size: 2.82051282051vw; text-align: center;  }
  .header .hd .nav button::after { width: 8.97435897436vw; height: 8.97435897436vw; right: 50%; transform: translate(50%, -50%); }
  .header .hd .nav button > span { display: none; }

  .header .hd .nav > ul { right: 19.23076923077vw; top: 6.41025641026vw; padding: 5.12820512821vw; margin: -5.12820512821vw -5.12820512821vw 0 0; }
  .header .hd .nav > ul > li { margin-top: 2.05128205128vw; }
  .header .hd .nav > ul > li:first-child { margin-top: 0px; }
  .header .hd .nav > ul > li::after { filter: drop-shadow(1px 2px 11px rgba(0, 0, 0, 0.25)); }
  .header .hd .nav > ul > li > a { width: 31.79487179487vw; height: 11.28205128205vw; line-height: 11.28205128205vw; font-size: 5.12820512821vw; border-radius: 1.28205128205vw; }

  main { padding-top: 0; justify-content: end; }
  .mainBox { padding-bottom: 0; width: 100%; overflow: hidden; overflow-x: scroll; }
  .futureraText { position: relative; order: 2; line-height: 120px; font-size: 151px; }
  .futureraText span { font-size: 164px; }
  
/*   .mainItem { order: 2; flex-direction: column;}
  .mainItem .num { position: relative; font-size: 3.58974358974vw;  }
  .mainItem .item01 { position: relative; width: calc( 100% - 20.51282051282vw); margin: 38.46153846154vw auto 0; text-align: center; top: inherit; left: inherit;}
  .mainItem .item02 { width:  calc( 100% - 20.51282051282vw); margin: 60vw auto 0; text-align: center;}
  .mainItem .item03 { position: relative; margin: 71.79487179487vw auto 0; width: calc( 100% - 20.51282051282vw); text-align: center; top: inherit; left: inherit; }
  .mainItem .item01 .unionText { top: -3vw; font-size: 3.58974358974vw;  } */

  .mainItem { order: 1; width: 782px; padding: 0px 15px; }
  .mainItem .num {  font-size: 3.58974358974vw;  }
  .mainItem .item01 { position: absolute; z-index: 3; width: 320px; padding-top: 10px; margin: 60px 0 0; text-align: center; top: 0; left: 15px; }
  .mainItem .item02 { position: relative; z-index: 2; width: 380px; text-align: center;  margin: 90px 0 0; transform: translateX(35px); }
  .mainItem .item03 { position: absolute; z-index: 1; width: 295px; text-align: center;  margin: 0; left: initial; top: 0; right: 15px; }
  .mainItem .item01 .unionText { top: 0px; font-size: 12px;  }

  .mainItem .num {  font-size: 12px; }
  .mainItem .link button { line-height: 19px; padding: 7px 10px; font-size: 22px; border-radius: 30px; }


  .mainItem .item01 .num { left: 70px; top: 35px; }
  .mainItem > div.item01 .link { left: 40px; top: 100px; }
  .mainItem .item02 .num { left: 120px; top: 20px;  }
  .mainItem > div.item02 .link { bottom: 80px; top: inherit; }
  .mainItem .item03 .num { top: 25px; left: inherit; right: 100px; }
  .mainItem > div.item03 .link { right: 0px; left: inherit; top: 90px; }
  /* 
  .mainItem .item01 .num, .mainItem .item02 .num, .mainItem .item03 .num, .mainItem > div.item01 .link, .mainItem > div.item02 .link, .mainItem > div.item03 .link { top: inherit; left: inherit; } */
 
  .footer { display: none; background: none; }
  .footer .ft { display: block; font-size: 3.58974358974vw; padding: 5.12820512821vw 9.74358974359vw; color: #B3B3B3; }
  .footer .ft address { margin-bottom: 0; font-size: 8px; }

  .popup .contact { position: relative; text-align: center; width: 100%;  }
  .popup .contact .img { width: 100%; padding: 0 5.12820512821vw; margin: 0 auto; }
  .popup .contact .link { width: 100%; padding: 0 5.12820512821vw; left: 0px; text-align: left; }
  .popup .contact .link a { text-align: left; font-size: 19.48717948718vw; color: #fff; }
  .popup .contact .link a span { font-family:"PPCirka", sans-serif; font-size: 21.48717948718vw; color: #1323E8; }
  .popup .contact .link a::before { content: ''; position: absolute; top: inherit; bottom: 0.5vw; transform: translateY(-50%); right: -21.53846153846vw; width: 11.53846153846vw; height: 11.53846153846vw; background: #000 url(images/ico/ico-arr.png) no-repeat center center; background-size: 50%; border-radius: 50%; }
  
  .popup .btnClose { position: fixed; z-index: 1002; top: 6.15384615385vw; left: 5.12820512821vw; padding: 2.5641025641vw; color: #fff; font-size: 3.58974358974vw; background: #000; border-radius: 8.71794871795vw; }
  .popup .close button { padding: 2.5641025641vw; font-size: 3.58974358974vw; border-radius: 8.71794871795vw;  }

  .popup.method .popupContent { padding: 51.28205128205vw 0 50px; }
  .popup.union .popupContent ,  .popup.archives .popupContent {  padding: 51.28205128205vw 0 50px; align-items: baseline; overflow-y: scroll; }
  
  .methodContent .visualPopup { width: calc(100% - 24.10256410256vw); height: 92.30769230769vw; padding: 2.82051282051vw 5.12820512821vw 0 5.12820512821vw; background: url(images/bg/bg-method-popup.png) no-repeat center top; background-size: 100%;  }
  .methodContent .visualPopup::after, .methodContent .visualPopup::before { display: none; }
  .methodContent .visualPopup strong { padding: 2.05128205128vw; font-size: 4.10256410256vw; }
  .methodContent .visualPopup .methodCt { margin-top: 12.82051282051vw; line-height: 4.87179487179vw; font-size: 4.10256410256vw; padding-left: 0px; }
  .methodContent .visualPopup .methodCt .ko { margin-top: 8.46153846154vw; }

  .methodContent .methodCont { transform: translateY(0px);}
  .methodContent .methodCont > div { margin-top: 14.44444444444vw; width: calc(100% - 24.10256410256vw); padding: 33.33333333333vw 5.12820512821vw 9.74358974359vw; line-height: 4.87179487179vw; font-size: 4.10256410256vw; }
  .methodContent .methodCont > div::before { width: calc(100% - 10.25641025642vw); left: 5.12820512821vw; top: 11.53846153846vw; }
  .methodContent .methodCont > div::after { width: 1.84615384615vw; height: 12.53333333333vw; top: 9.48717948718vw; background: url(images/ico/ico-line-mobi.png) no-repeat; background-size: 100%; }

  .textType01 { font-size: 7.55555555556vw; }
  .textType01 span { font-size: 8.55555555556vw; }
  .popup.method .textType01 { margin: 27.77777777778vw 0; transform: translateY(0px);}
  

  .mobift { position: relative; width: 782px; order: 3; display: flex; justify-content: space-between; padding: 15px; color: #B3B3B3; }
  .mobift address { font-style: normal;}

  .unionContent { width: calc(100% - 24.10256410256vw); height: auto; background: none;  }
  .unionContent .unionHeader { padding: 2.82051282051vw 0 18.46153846154vw; background: url(images/bg/bg-union-top.png) no-repeat center top; background-size: 100%; }
  .unionContent .unionBottom { padding-bottom: 10vw; background: url(images/bg/bg-union-bottom.png) no-repeat center bottom; background-size: 100%; }
  .unionContent strong { padding: 2.05128205128vw; font-size: 4.10256410256vw; }
  .unionContent .partners { display: block; text-align: center; align-items: center; padding: 0px 8.88888888889vw; background: url(images/bg/bg-unionCt.png); }
  .unionContent .partners:nth-child(2) { margin-top: 0; padding: 0px 11.25vw; }
  .unionContent .partners:nth-child(3) { margin-top: 0; padding: 0 ; }
  .unionContent .partners:nth-child(4) { margin-top: 0; }
  .unionContent .partners .ptn { padding: 18.46153846154vw 0 0; margin: 0 auto 0; }
  .unionContent .partners .fp { width: 18.46153846154vw; margin: 0 auto; padding: 0; }
  .unionContent .partners .jr { width: 46.15384615385vw; }
  .unionContent .partners .of { width: 43.07692307692vw; }
  .unionContent .partners .lsg { width: 40.10256410256vw; }
  .unionContent .partners .av { width: 36.41025641026vw; }
  .unionContent .partners .mj { width: 26.92307692308vw; }
  .unionContent .partners .mmt { width: 45.92307692308vw; }
  .unionContent .partners .bb { width: 26.66666666667vw; }
  .unionContent .partners .kratio { width: 26.05128205128vw; }
  .unionContent .partners .tgp { width: 52.5641025641vw; }
  .unionContent .partners .seg { width: 33.28205128205vw; padding-bottom: 25.64102564103vw; }

  .archivesContent { display: block; padding: 0 12.05128205128; }
  .archivesContent .archives_left { width: 100%; text-align: center; background: none; }
  .archivesContent .archives_left strong { margin: 0 auto; padding: 10px; font-size: 20px; border-radius: 10px; }
  .archivesContent .archives_left .ar-img { display: none; }
  .archivesContent .imgBox { text-align: center; }

  .archivesContent .archives_right { margin-left: 0; width: 100%; padding: 0; }
  .archivesContent .archives_right::before { display: none; }
  .archivesContent .archives_right::after { display: none; }
  .archivesContent .archives_right > div { height: auto; padding: 0 0 3.47222222222vw; overflow-y:auto; }
  .archivesContent .archives_right strong { display: inline-block; padding: 2.5641025641vw; font-size: 3.58974358974vw; border-radius: 5.12820512821vw; }

  .archivesContent .archives_right .futreaNews { margin-top: 20.51282051282vw; }
  .archivesContent .archives_right .futreaNews.unionNews { margin-top: 20.51282051282vw; }
  .archivesContent .archives_right .futreaNews > ul > li { margin-top: 10.25641025641vw; }
  .archivesContent .archives_right .futreaNews > ul > li:first-child { margin-top: 7.69230769231vw; }
  .archivesContent .archives_right .newsBox { position: relative;  padding: 6.15384615385vw 7.69230769231vw; border-radius: 10.76923076923vw; }
  .archivesContent .archives_right .newsBox .tags span { margin-left: 4px; padding: 1.02564102564vw 2.5641025641vw; font-size: 3.07692307692vw; border-radius: 7.69230769231vw; }
  .archivesContent .archives_right .newsBox .tags span:first-child { margin-left: 0px;}
  .archivesContent .archives_right .newsBox .newsContent { margin-top: 7.69230769231vw; font-size: 4.10256410256vw; }
  .archivesContent .archives_right .newsBox .newsTitle { font-size: 6.15384615385vw; text-transform: uppercase; }
  .archivesContent .archives_right .newsBox a {  bottom: 5.12820512821vw; right: 7.69230769231vw; width: 8.97435897436vw; height: 8.97435897436vw; }


}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  display: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}